import React from "react";
import FeaturedPostHero from "../components/page-components/FeaturesPost/FeaturesPostHero";
import FlexibleContent from "../components/reusable/FlexibleContent";
import { graphql } from "gatsby";
import { Styling } from "../styles/templates/features-post.styled";
import Seo from "../components/global/seo";

export default function FeaturesPost({ data }) {
  return (
    <Styling>
      <Seo title={data.hero.seo.title} description={data.hero.seo.metaDesc} />
      <FeaturedPostHero hero={data.hero} />
      <FlexibleContent flexible={data.flexible} slug={data.hero.slug} />
    </Styling>
  );
}

export const query = graphql`
  query ($id: String!) {
    hero: wpPage(id: { eq: $id }) {
      title
      slug
      seo {
        metaDesc
        title
      }
      acf_hero {
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1500, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
    flexible: wpPage(id: { eq: $id }) {
      flexibleContent {
        postBuilder {
          ... on WpPage_Flexiblecontent_PostBuilder_TextBlock {
            fieldGroupName
            text
          }
          ... on WpPage_Flexiblecontent_PostBuilder_Quote {
            fieldGroupName
            quoteText
          }
          ... on WpPage_Flexiblecontent_PostBuilder_AudioPlayer {
            fieldGroupName
            trackTile
            audioFile {
              localFile {
                publicURL
              }
            }
          }
          ... on WpPage_Flexiblecontent_PostBuilder_ImageBlock {
            fieldGroupName
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500, placeholder: BLURRED, formats: [AUTO, WEBP], layout: FULL_WIDTH)
                }
              }
            }
          }
          ... on WpPage_Flexiblecontent_PostBuilder_VideoBlock {
            fieldGroupName
            video
          }

          ... on WpPage_Flexiblecontent_PostBuilder_SnipcartButton {
            fieldGroupName
            price
            productDescription
            productName
          }
        }
      }
    }
  }
`;
